"use strict";

(function ($) {
  Drupal.behaviors.researchData = {
    map: null,
    researchList: null,
    currentlyLoaded: null,
    $list: null,
    $filter: null,
    $details: null,
    $loading: null,
    attach: function attach(context, settings) {
      var self = this;
      self.map = Drupal.behaviors.researchMap.map;
      self.$list = $('.m-research-map__list');
      self.$filter = $('.m-research-map__filter');
      self.$details = $('.m-research-map__details');
      self.$loading = $('.m-research-map__loading');

      if (self.$list.length === 0) {
        return;
      }

      self.settings = settings.clew_events;
      self.initializeList();
      self.researchList.on('updated', Drupal.behaviors.researchMap.initializeData);
      self.researchList.on('updated', self.processResults);
      self.$list = $('.m-research-map__list');
      self.$list.on('click', 'a', self.openByClick);
      self.$list.on('openByNid', self.openByNid);
      self.$list.on('hideDetails', self.hideDetails);
      $(document).on('click', 'button.close', self.close);
      $(document).on('click', '.m-research-map__list__item__city', self.onLocationClick);
    },
    initializeList: function initializeList() {
      var listOptions = {
        listClass: 'm-research-map__list',
        searchClass: 'm-research-map__search__input',
        valueNames: ['title', 'city']
      };
      this.researchList = new List('research-list', listOptions);
      this.processResults();
    },
    processResults: function processResults() {
      var self = Drupal.behaviors.researchData;
      var results = self.researchList.matchingItems.length;
      var $noResultInfo = $('.m-research-map__list__no-results').addClass('hidden');

      if (results) {
        $noResultInfo.addClass('hidden');
      } else {
        $noResultInfo.removeClass('hidden');
      }
    },
    load: function load(nid) {
      var url = "/event-item-details/".concat(nid);
      return new Promise(function (resolve, reject) {
        $.getJSON(url).done(resolve);
      });
    },
    openByNid: function openByNid(e, nid) {
      var self = Drupal.behaviors.researchData;
      self.open(nid);
    },
    openByClick: function openByClick(e) {
      e.preventDefault();
      var self = Drupal.behaviors.researchData;
      var $item = $(this).parents('li');
      var nid = $item.data('nid');
      self.open(nid);
      $('html, body').stop().animate({
        scrollTop: 0
      }, 500, 'swing');
    },
    open: function open(nid) {
      var _this = this;

      this.$loading.removeClass('hidden');
      this.load(nid).then(function (data) {
        return _this.build(data);
      });
    },
    build: function build(_ref) {
      var title = _ref.title,
          teaser = _ref.teaser,
          tags = _ref.tags,
          nid = _ref.nid,
          path = _ref.path;
      var self = Drupal.behaviors.researchData;
      self.currentlyLoaded = parseInt(nid, 10);
      var details = "\n        <button class=\"close\">&times;</button>\n        <h3>".concat(title, "</h3>");

      if (tags.length) {
        details += "<div class=\"tags\">";
        tags.forEach(function (tag) {
          details += "<span class=\"tag\">#".concat(tag.name, "</span>");
        });
        details += "</div>";
      }

      if (teaser) {
        details += "<div class=\"teaser\">".concat(teaser, "</div>");
      }

      details += "<a href=\"/".concat(path, "\" class=\"open\">Show Details</a>");
      self.$details.html(details);
      self.showDetails();
      self.$list.trigger('detailsReady');
    },
    showDetails: function showDetails() {
      this.$details.removeClass('hidden');
      this.$loading.addClass('hidden');
      this.$list.trigger('showDetails', [{
        nid: this.currentlyLoaded
      }]);
    },
    closeDetails: function closeDetails() {
      this.$details.addClass('hidden');
      this.$loading.addClass('hidden');
      this.$list.trigger('closeDetails');
    },
    close: function close() {
      var self = Drupal.behaviors.researchData;
      self.$details.empty();
      self.closeDetails();
    },
    hideDetails: function hideDetails() {
      var self = Drupal.behaviors.researchData;
      self.$details.addClass('hidden');
    },
    onLocationClick: function onLocationClick(e) {
      var nid = $(this).parents('li').data('nid');
      var researchMap = Drupal.behaviors.researchMap;
      var feature = researchMap.geojson.features.find(function (feature) {
        return nid === feature.properties.nid;
      });
      var coordinates = feature.geometry.coordinates.slice();
      $('html, body').stop().animate({
        scrollTop: 0
      }, 500, 'swing');
      researchMap.flyTo(coordinates, 6);
      researchMap.popup.setLngLat(coordinates).setHTML(researchMap.buildPopup(feature.properties)).addTo(researchMap.map);
    },
    onItemMouseleave: function onItemMouseleave(e) {
      var researchMap = Drupal.behaviors.researchMap;
      researchMap.map.getCanvas().style.cursor = '';
      researchMap.popup.remove();
    }
  };
})(jQuery);
"use strict";

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function ($) {
  Drupal.behaviors.researchMap = {
    map: null,
    geojson: null,
    initialGeojson: null,
    settings: null,
    markers: new Map(),
    popup: null,
    noResultsClass: '.research-map-no-results',
    $list: null,
    $serachBox: null,
    attach: function attach(context, settings) {
      var self = this;
      self.$list = $('.m-research-map__list');

      if (self.$list.length === 0) {
        return;
      }

      self.settings = settings.clew_events;
      self.$searchBox = $('.m-research-map__search__input');
      self.popup = new mapboxgl.Popup({
        offset: 15,
        closeButton: false,
        closeOnClick: false
      });
      self.initializeMap();
      self.initializeData();
      self.addControls();
      self.$list.on('showDetails', self.onShowDetails);
      self.$list.on('closeDetails', self.resetView);
      self.$list.on('detailsReady', self.closePopups);
      Drupal.behaviors.researchData.researchList.on('updated', self.filterGeodata);
      self.map.on('load', self.createLayers);
      ['event', 'interest_point'].forEach(function (source) {
        var sourceID = "features-".concat(source, "-unclustered");
        self.map.on('click', sourceID, self.onMarkerClick);
        self.map.on('mouseenter', sourceID, self.showPopup);
        self.map.on('mouseleave', sourceID, self.hidePopup);
      });
    },
    clearMarkers: function clearMarkers() {
      this.markers.forEach(function (marker) {
        return marker.remove();
      });
      this.markers.clear();
    },
    initializeMap: function initializeMap() {
      if ($('#research-map').size() === 0) {
        return;
      }

      mapboxgl.accessToken = Drupal.settings.clew_events.mapboxApiToken;
      this.map = new mapboxgl.Map({
        container: 'research-map',
        style: 'mapbox://styles/mapbox/light-v9',
        center: [10, 45],
        zoom: 3,
        maxZoom: 11,
        preserveDrawingBuffer: true
      });
      this.map.scrollZoom.disable();
    },
    initializeData: function initializeData() {
      var self = Drupal.behaviors.researchMap; // self.clearMarkers();

      self.parseFeaturesFromList().then(function (data) {
        self.hasNoResults = false;
        self.geojson = data;
        self.initialGeojson = data; // self.addMarkersToMap();

        self.fitToBounds();
        self.noResultsMessage(false);
      }).catch(function (err) {
        self.noResultsMessage(true);
      });
    },
    addControls: function addControls() {
      var self = Drupal.behaviors.researchMap;
      var nav = new mapboxgl.NavigationControl({
        showCompass: false
      });
      self.map.addControl(nav, 'top-left');
    },
    filterGeodata: function filterGeodata() {
      var self = Drupal.behaviors.researchMap;
      var researchList = Drupal.behaviors.researchData.researchList;
      self.geojson = _objectSpread({}, self.initialGeojson);

      if (researchList.matchingItems.length) {
        self.geojson.features = self.geojson.features.filter(function (feature) {
          return researchList.matchingItems.findIndex(function (item) {
            return parseInt($(item.elm).data('nid'), 10) === feature.properties.nid;
          });
        });
      } else {
        self.geojson.features = [];
      } // timeout to remove from callstack


      setTimeout(function () {
        return self.updateFeatures();
      }, 0);
    },
    updateFeatures: function updateFeatures() {
      var self = Drupal.behaviors.researchMap;
      var sources = self.getSourcesByType();
      sources.forEach(function (source) {
        var sourceID = "features-".concat(source.dataType);
        self.map.getSource(sourceID).setData(source);
      });
    },
    fitToBounds: function fitToBounds() {
      var self = Drupal.behaviors.researchMap;
      var bounds = new mapboxgl.LngLatBounds();
      self.geojson.features.forEach(function (feature) {
        bounds.extend(feature.geometry.coordinates);
      });
      self.map.fitBounds(bounds, {
        padding: {
          top: 50,
          bottom: 200,
          left: 50,
          right: 50
        }
      }, 1000);
    },
    getSourcesByType: function getSourcesByType() {
      var events = _objectSpread({}, this.geojson);

      events.dataType = 'event';
      events.color = '#FAC600';
      events.features = events.features.filter(function (item) {
        return item.properties.type === events.dataType;
      });

      var interestPoints = _objectSpread({}, this.geojson);

      interestPoints.dataType = 'interest_point';
      interestPoints.color = '#009870';
      interestPoints.features = interestPoints.features.filter(function (item) {
        return item.properties.type === interestPoints.dataType;
      });
      return [events, interestPoints];
    },
    createLayers: function createLayers() {
      var self = Drupal.behaviors.researchMap;
      var sources = self.getSourcesByType();
      sources.forEach(function eachFilter(source, index) {
        var sourceID = "features-".concat(source.dataType);
        self.map.addSource(sourceID, {
          type: 'geojson',
          data: source,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50
        });
        self.map.addLayer({
          id: "".concat(sourceID, "-clusters"),
          type: "circle",
          source: sourceID,
          filter: ["has", "point_count"],
          paint: {
            "circle-color": source.color,
            "circle-radius": ["step", ["get", "point_count"], 15, 2, 20, 5, 30, 10, 40]
          }
        });
        self.map.addLayer({
          id: "".concat(sourceID, "-cluster-count"),
          type: "symbol",
          source: sourceID,
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Arial Unicode MS Bold"],
            "text-size": 12
          }
        });
        self.map.addLayer({
          id: "".concat(sourceID, "-unclustered"),
          type: "circle",
          source: sourceID,
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-color": source.color,
            "circle-radius": 10
          }
        });
      });
    },
    addMarkersToMap: function addMarkersToMap() {
      var _this = this;

      this.geojson.features.forEach(function (feature) {
        var marker = _this.buildMarker(feature);

        marker.addTo(_this.map);

        _this.markers.set(feature.properties.nid, marker);
      });
    },
    buildMarker: function buildMarker(feature) {
      var el = document.createElement('div');
      el.className = "marker ".concat(feature.properties.type);
      el.feature = feature;
      return new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates);
    },
    buildPopup: function buildPopup(_ref) {
      var title = _ref.title,
          description = _ref.description;
      return "<span class=\"type mute\">".concat(description, "</span><br/>\n              <span class=\"title\">").concat(title, "</span>");
    },
    parseFeaturesFromList: function parseFeaturesFromList() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        var self = _this2;
        var features = [];
        self.$list.children('li:visible').each(function () {
          var built = self.buildFeatureFromRow($(this));

          if (built !== false) {
            features.push(built);
          }
        });

        if (features.length) {
          resolve({
            type: 'FeatureCollection',
            'features': features
          });
        } else {
          reject('No results for current search');
        }
      });
    },
    buildFeatureFromRow: function buildFeatureFromRow($item) {
      var lat = $item.data('lat');
      var lng = $item.data('lng');

      if (lat === 0 && lng === 0) {
        return false;
      }

      var nid = $item.data('nid');
      var type = $item.data('type');
      var title = $('.m-research-map__list__item__title', $item).text();
      var description = $item.data('description');
      var city = $item.data('city');
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lat, lng]
        },
        properties: {
          nid: nid,
          title: title,
          type: type,
          description: description,
          city: city
        }
      };
    },
    noResultsMessage: function noResultsMessage(show) {
      if (show) {
        $(this.noResultsClass).removeClass('hidden');
      } else {
        $(this.noResultsClass).addClass('hidden');
      }
    },
    highlightMarker: function highlightMarker(event, _ref2) {
      var nid = _ref2.nid;
      var self = Drupal.behaviors.researchMap;
      var marker = self.markers.get(parseInt(nid, 10));
      var feature = self.geojson.features.find(function (item) {
        return item.properties.nid === parseInt(nid, 10);
      });
      $('.marker.active').removeClass('active');
      $(marker._element).addClass('active');
      self.flyTo(feature.geometry.coordinates);
    },
    flyTo: function flyTo(coordinates) {
      var zoom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 11;
      this.map.flyTo({
        center: coordinates,
        zoom: zoom,
        screenSpeed: .8
      });
    },
    resetView: function resetView() {
      var self = Drupal.behaviors.researchMap;
      $('.marker.active').removeClass('active');
      self.fitToBounds();
    },
    onShowDetails: function onShowDetails(e, _ref3) {
      var nid = _ref3.nid;
      var self = Drupal.behaviors.researchMap;

      var _self$geojson$feature = self.geojson.features.find(function (feature) {
        return feature.properties.nid === nid;
      }),
          geometry = _self$geojson$feature.geometry;

      self.flyTo(geometry.coordinates);
    },
    onMarkerClick: function onMarkerClick(e) {
      var self = Drupal.behaviors.researchMap;
      self.$list.trigger('hideDetails');
      var _e$features$ = e.features[0],
          geometry = _e$features$.geometry,
          properties = _e$features$.properties;
      $(e.target).addClass('active');
      self.flyTo(geometry.coordinates);
      self.$list.trigger('openByNid', properties.nid);
    },
    showPopup: function showPopup(e) {
      var feature = e.features[0];
      var self = Drupal.behaviors.researchMap;
      var coordinates = feature.geometry.coordinates.slice();
      self.map.getCanvas().style.cursor = 'pointer';
      self.popup.setLngLat(coordinates).setHTML(self.buildPopup(feature.properties)).addTo(self.map);
    },
    hidePopup: function hidePopup() {
      var self = Drupal.behaviors.researchMap;
      self.map.getCanvas().style.cursor = '';
      self.popup.remove();
    }
  };
})(jQuery);
"use strict";

(function ($) {
  Drupal.behaviors.travelLogMap = {
    map: null,
    geojson: null,
    popup: null,
    $list: null,
    $serachBox: null,
    attach: function attach(context, settings) {
      var self = this;
      self.$list = $('.travel-log .list-items');

      if (self.$list.length === 0) {
        return;
      }

      self.popup = new mapboxgl.Popup({
        offset: 15,
        closeButton: false,
        closeOnClick: false
      });
      self.initializeMap();
      self.initializeData();
      self.addControls();
      self.map.on('load', self.createLayers);
      self.map.on('mouseenter', 'features-travel-log', self.showPopup);
      self.map.on('mouseleave', 'features-travel-log', self.hidePopup);
      $(document).on('click', '.m-travel-log__item', function (e) {
        e.preventDefault();

        if ($(this).hasClass('active')) {
          $(this).removeClass('active');
        } else {
          $(this).slideDown(500).addClass('active');
        }
      });
    },
    initializeMap: function initializeMap() {
      if ($('#travel-log-map').size() === 0) {
        return;
      }

      mapboxgl.accessToken = Drupal.settings.clew_events.mapboxApiToken;
      this.map = new mapboxgl.Map({
        container: 'travel-log-map',
        style: 'mapbox://styles/mapbox/light-v9',
        center: [10, 45],
        zoom: 3,
        maxZoom: 11,
        preserveDrawingBuffer: true
      });
      this.map.scrollZoom.disable();
    },
    initializeData: function initializeData() {
      var self = Drupal.behaviors.travelLogMap;
      self.parseFeaturesFromList().then(function (data) {
        self.geojson = data;
        self.fitToBounds();
      });
    },
    fitToBounds: function fitToBounds() {
      var self = Drupal.behaviors.travelLogMap;
      var bounds = new mapboxgl.LngLatBounds();
      self.geojson.features.forEach(function (feature) {
        bounds.extend(feature.geometry.coordinates);
      });
      self.map.fitBounds(bounds, {
        padding: {
          top: 50,
          bottom: 50,
          left: 50,
          right: 50
        }
      }, 1000);
    },
    parseFeaturesFromList: function parseFeaturesFromList() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var self = _this;
        var features = [];
        self.$list.children('li').each(function () {
          var built = self.buildFeatureFromRow($(this));

          if (built !== false) {
            features.push(built);
          }
        });

        if (features.length) {
          resolve({
            type: 'FeatureCollection',
            'features': features
          });
        }
      });
    },
    buildFeatureFromRow: function buildFeatureFromRow($item) {
      var lat = $item.data('lat');
      var lng = $item.data('lng');

      if (lat === 0 && lng === 0) {
        return false;
      }

      var nid = $item.data('nid');
      var type = $item.data('type');
      var title = $item.data('title');
      var city = $item.data('city');
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lat, lng]
        },
        properties: {
          nid: nid,
          title: title,
          type: type,
          city: city
        }
      };
    },
    createLayers: function createLayers() {
      var self = Drupal.behaviors.travelLogMap;
      var sourceID = "features-travel-log";
      self.map.addSource(sourceID, {
        type: 'geojson',
        data: self.geojson,
        cluster: false
      });
      self.map.addLayer({
        id: sourceID,
        type: "circle",
        source: sourceID,
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": '#FAC600',
          "circle-radius": 15
        }
      });
    },
    buildPopup: function buildPopup(_ref) {
      var title = _ref.title;
      return "<span class=\"title\">".concat(title, "</span>");
    },
    showPopup: function showPopup(e) {
      var feature = e.features[0];
      var self = Drupal.behaviors.travelLogMap;
      var coordinates = feature.geometry.coordinates.slice();
      self.map.getCanvas().style.cursor = 'pointer';
      self.popup.setLngLat(coordinates).setHTML(self.buildPopup(feature.properties)).addTo(self.map);
    },
    hidePopup: function hidePopup() {
      var self = Drupal.behaviors.travelLogMap;
      self.map.getCanvas().style.cursor = '';
      self.popup.remove();
    },
    addControls: function addControls() {
      var nav = new mapboxgl.NavigationControl({
        showCompass: false
      });
      this.map.addControl(nav, 'top-left');
    }
  };
})(jQuery);